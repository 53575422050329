<template>
  <v-container fluid>
    <template>
      <div class="text-center">
        <v-overlay
          :value="isLoading"
          z-index="99999"
          class="text-h6"
          opacity="0.8"
          ><v-progress-circular
            indeterminate
            color="#3564ac"
          ></v-progress-circular>
          <v-spacer></v-spacer>
          Uploading spreadsheet...
        </v-overlay>
      </div>
    </template>
    <v-dialog v-model="dialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text"> Are you sure? </v-card-title>
        <v-card-text class="mt-2 text-body-1 text-center">
          Are you sure you want to cancel?<br />
          This will result in the projects not being imported. <br />
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col class="grow" align="right">
              <v-btn
                @click="dialog = false"
                plain
                class="msaBlue--text font-weight-bold"
              >
                No
              </v-btn>
            </v-col>
            <v-col class="shrink" align="right">
              <v-btn @click="cancel" plain class="msaBlue white--text">
                Yes, cancel the upload
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Import Projects
          </v-card-title>
          <v-card-text>
            <v-alert class="mt-4" text color="info" dense>
              <div>
                <div class="text-h6 black--text">Instructions</div>
                <ul style="list-style: none">
                  <li class="text-body-2 black--text">
                    <strong>Step 1:</strong> Download the
                    <a href="../projectUpload.xlsx"
                      >Project Upload Spreadsheet.</a
                    >
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 2:</strong> Open the project upload spreadsheet
                    with Microsoft Excel.
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 3:</strong> Enter each project on a separate
                    line. *A maximum of 100 Projects can be uploaded at a time.
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 4:</strong> <strong>SAVE</strong> and
                    <strong>CLOSE</strong> the file when you are done editing.
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 5:</strong> Press the
                    <strong>Upload Spreadsheet</strong>
                    button and select your file to upload it.
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 6:</strong> Verify Project data which will
                    appear in a table below. Resolve any issues that might have
                    occurred.
                  </li>
                  <li class="text-body-2 black--text">
                    <strong>Step 7:</strong> Press the
                    <strong>Confirm</strong> button to complete the import
                    process.
                  </li>
                </ul>
              </div>
            </v-alert>
            <v-card-actions
              ><v-row v-if="!isImporting">
                <v-btn dark color="msaBlue" href="../projectUpload.xlsx"
                  >Download SpreadSheet</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn dark color="msaBlue" @click="handleFileImport"
                  >Upload SpreadSheet</v-btn
                >
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="onFileChanged"
                />
              </v-row>
              <v-row v-else>
                <v-btn dark color="red" @click="showCancelConfirm"
                  >Cancel</v-btn
                >
                <v-spacer> </v-spacer>
                <v-btn color="success" @click="confirm">Confirm</v-btn>
              </v-row>
            </v-card-actions>
          </v-card-text>
          <v-form ref="form" @submit.prevent :lazy-validation="false">
            <div v-for="project in projects" :key="project.itemKey">
              <v-expand-transition>
                <v-card-text v-show="project.isExpanded">
                  <v-container style="border: thin gray dotted">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          maxlength="1000"
                          counter
                          class="py-0"
                          dense
                          required
                          outlined
                          label="Name"
                          v-model="project.name"
                          :rules="[rules.name, rules.required]"
                          :error="project.errors.name.length > 0"
                          :error-messages="project.errors.name"
                          @input="
                            () => {
                              project.errors.name = '';
                            }
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          maxlength="1000"
                          counter
                          class="py-0"
                          dense
                          required
                          outlined
                          label="Address"
                          v-model="project.address"
                          :error-messages="project.errors.address"
                          @input="
                            () => {
                              project.errors.address = '';
                            }
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          class="py-0"
                          dense
                          required
                          outlined
                          label="Start Date"
                          hint="yyyy-mm-dd"
                          v-model="project.startDate"
                          :rules="[rules.date]"
                          :error-messages="project.errors.startDate"
                          @input="
                            () => {
                              project.errors.startDate = '';
                            }
                          "
                        >
                        </v-text-field>
                        <v-text-field
                          class="py-0"
                          dense
                          required
                          outlined
                          label="End Date"
                          hint="yyyy-mm-dd"
                          v-model="project.endDate"
                          :rules="[rules.date]"
                          :error-messages="project.errors.endDate"
                          @input="
                            () => {
                              project.errors.endDate = '';
                            }
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-textarea
                          class="py-0"
                          dense
                          required
                          outlined
                          label="Description"
                          rows="4"
                          v-model="project.description"
                          :error-messages="project.errors.description"
                          @input="
                            () => {
                              project.errors.description = '';
                            }
                          "
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense class="mt-n4 ml-2">
                      <span class="my-0 py-0">Active</span>
                      <v-checkbox
                        class="my-0 py-0 ml-2"
                        dense
                        v-model="project.isActive"
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            text
                            dark
                            color="red"
                            class="ml-3"
                            v-on="on"
                            @click="removeProject(project)"
                          >
                            <v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        <span>Remove Project</span>
                      </v-tooltip>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-expand-transition>
            </div>
          </v-form>
          <v-card-text>
            <v-card-actions>
              <v-row v-if="isImporting">
                <v-btn dark color="red" @click="showCancelConfirm"
                  >Cancel</v-btn
                >
                <v-spacer> </v-spacer>
                <v-btn color="success" @click="confirm">Confirm</v-btn>
              </v-row></v-card-actions
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'CompanyProjectsImportPage',
  data() {
    return {
      isLoading: false,
      selectedFile: null,
      dialog: false,
      rules: {
        name: (v) => {
          return !v || !!v.trim().length || 'This field is required.';
        },
        required: (v) => !!v || 'This field is required.',
        date: (v) => this.$helpers.validateDate(v),
      },
      projects: [],
    };
  },
  computed: {
    isImporting() {
      return this.projects.length > 0;
    },
  },
  methods: {
    removeProject(project) {
      project.isExpanded = false;
      setTimeout(() => {
        this.projects = this.projects.filter(
          (p) => p.itemKey != project.itemKey,
        );
      }, 300);
    },
    showCancelConfirm() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.selectedFile = null;
      this.projects = [];
      if (this.$refs.uploader) {
        this.$refs.uploader.value = null;
      }
    },
    hasModelErrors() {
      return this.projects.some((i) => {
        for (const key in i.errors) {
          if (i.errors[key].length > 0) {
            return true;
          }
        }
        return false;
      });
    },
    confirm() {
      if (!this.$refs.form.validate() || this.hasModelErrors()) {
        this.$root.showMessage(
          'Validation',
          'Please address all validation errors highlighted in red.',
          () => false,
          () => false,
          'OK',
          null,
        );
        return;
      }
      this.importProjects();
    },
    importProjects() {
      const params = {
        projects: this.projects,
        loaderText: 'Importing projects...',
      };
      this.$axios
        .post('import-projects?format=json', params)
        .then((response) => {
          if (response.data.result == true) {
            this.$router.push({
              name: 'CompanyProjects',
            });
          } else {
            /*
              we process only the errors since the 
              backend modifies the data (date conversion)
              so we cannot use it when it comes back.
            */
            this.processErrors(response.data.projects);
          }
        });
    },
    processErrors(projects) {
      this.projects.map((p1) => {
        projects.map((p2) => {
          if (p1.itemKey === p2.itemKey) {
            p1.errors = p2.errors;
          }
        });
      });
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    async onFileChanged(event) {
      this.selectedFile = event.target.files[0];
      if (!this.selectedFile) {
        return;
      }

      try {
        await this.$helpers.processFile(
          this.selectedFile,
          this.$constants.ACCEPTED_MIME_TYPES.EXCEL,
          true,
        );
      } catch (error) {
        if (error.message) {
          this.$root.showMessage(
            'Error',
            error.message,
            () => null,
            null,
            'ok',
          );
        }
        this.selectedFile = null;
        return;
      }

      this.uploadFile(this.selectedFile);
    },
    uploadFile(data) {
      this.isLoading = true;
      const params = new FormData();
      params.append('file', data);
      params.append('name', data.name);
      params.append('jwtToken', this.$store.getters.jwtToken);
      return this.$axios
        .post('upload-project-import?format=json', params, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          this.isLoading = false;
          this.projects = response.data;
          return response;
        })
        .catch((error) => {
          this.cancel();
          this.isLoading = false;
          return error;
        });
    },
  },
  beforeMount() {
    this.$axios.post('public/ping?format=json', {});
  },
};
</script>
